import type { BlockLevelVisibilityConfig, BlockVisibilityCondition } from '@root/@types/types';

import type { BlockVisibilityOperatorsConfig } from './types';

export const MATCH_OPTIONS = [
  { name: 'OR', value: 'OR' },
  { name: 'AND', value: 'AND' },
];

export const MODE_OPTIONS = [
  { name: 'HIDE THE BLOCK', value: 'HIDE' },
  { name: 'SHOW THE BLOCK', value: 'SHOW' },
];

export const VARIATION_MODE_OPTIONS = [{ name: 'Auto select this variation', value: 'AUTOSELECT' }];

export const EMPTY_CONDITION_STATE: BlockVisibilityCondition = {
  field: '',
  operator: 'IS NOT EMPTY',
  value: '',
};

export const INITIAL_BLOCK_VISIBILITY_STATE: BlockLevelVisibilityConfig = {
  conditions: [EMPTY_CONDITION_STATE],
  match: 'OR',
  mode: 'HIDE',
};

const INVALID_CONF_MSG = 'Configuration for this block is not valid';
export const INVALID_CONF_TOOLTIP = {
  block: `${INVALID_CONF_MSG}. Please ensure block has at least one valid condition before applying changes.`,
  variation: `${INVALID_CONF_MSG}. Please ensure all variations have conditional logic before applying changes.`,
};

export const OPERATOR_OPTIONS: BlockVisibilityOperatorsConfig = [
  { name: 'IS', value: 'IS' },
  { name: 'IS NOT', value: 'IS NOT' },
  { name: 'IS EMPTY', value: 'IS EMPTY' },
  { name: 'IS NOT EMPTY', value: 'IS NOT EMPTY' },
];

export const PRESENCE_STATUS_OPERATORS: BlockVisibilityOperatorsConfig = [
  { name: 'IS EMPTY', value: 'IS EMPTY' },
  { name: 'IS NOT EMPTY', value: 'IS NOT EMPTY' },
];
