import { authProvider } from '@helpers/auth';
import type { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';

export const getRequestAuthPayload = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const isV2 = import.meta.env?.VITE_CLIENT === 'ct-ppl';
  if (isV2) config.url = config.url?.replaceAll('-spa-al', '');

  const controller = new AbortController();

  const baseConfig: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: import.meta.env?.VITE_API_URL,
    signal: controller.signal,
  };

  const networkAuthPayload = await authProvider.getNetworkAuthPayload();

  if (!networkAuthPayload) {
    controller.abort('No auth payload received');
  }

  return merge(baseConfig, networkAuthPayload ?? {}, config);
};
