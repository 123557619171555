import { type FC, useMemo } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import type { Team } from '@root/@types/types';

import type { EntityModalType, StepProps } from '../types';
import { Step } from '../types';
import { getStepComponents } from '../utils';

import { UploadMrcDocumentStep } from './submission/UploadMrcDocumentStep';

interface FormContentProps extends StepProps {
  currentStep: Step;
  isLoading: boolean;
  type: EntityModalType;
  userTeams: Team[];
}

export const FormContent: FC<FormContentProps> = ({ currentStep, isLoading, type, userTeams, ...stepProps }) => {
  const isSubmissionUpload = type === 'contract';
  const { Step1, Step2, Step3 } = useMemo(() => getStepComponents(type), [type]);

  return (
    <ShowFirstMatching>
      <Show when={currentStep === Step.GeneralEntityData}>
        <Step1 {...stepProps} currentStep={currentStep} userTeams={userTeams} />
      </Show>
      <Show when={currentStep === Step.BaseEntityData}>
        <Step2 {...stepProps} />
      </Show>
      <Show when={currentStep === Step.BrandingInfo}>
        <Step3 {...stepProps} />
      </Show>
      <Show when={isSubmissionUpload && currentStep === Step.MrcFileUpload}>
        <UploadMrcDocumentStep {...stepProps} isCreating={isLoading} value={stepProps.formValues?.file} />
      </Show>
    </ShowFirstMatching>
  );
};
