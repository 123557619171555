import type { UpdateEndorsementDataPayload } from '@root/@types/types';
import { EndorsementPremiumChange } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import dayjs from 'dayjs';
import { z } from 'zod';

import type { FormState } from './types';
import { isConditionalPremiumOption } from './types';

export const createEndorsementStep1Schema = z.object({
  effective_date: z
    .string()
    .datetime({ offset: false, local: true })
    .refine((value) => dayjs(value).isValid(), { message: 'Invalid date format' }),
  expiry_date: z
    .string()
    .datetime({ offset: false, local: true })
    .refine((value) => dayjs(value).isValid(), { message: 'Invalid date format' }),
  reference: z.string().min(1, { message: 'Reference is required' }),
  type: z.string(),
});

export const createEndorsementStep2Schema = z
  .object({
    additional_premium: z.object({ amount: z.number(), currency: z.string() }).nullable().optional(),
    premium_change: z.nativeEnum(EndorsementPremiumChange),
    reason: z.string(),
  })
  .superRefine((data, ctx) => {
    const hasAdditionalPremium =
      !isEmptyValue(data.additional_premium?.amount) && !isEmptyValue(data.additional_premium?.currency);

    if (isConditionalPremiumOption(data.premium_change) && !hasAdditionalPremium) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Additional premium is required' });
    }
  });

export const createEndorsementFormSchema = z.union([createEndorsementStep1Schema, createEndorsementStep2Schema]);

export const isEndorsementStep1Valid = (state: FormState): state is UpdateEndorsementDataPayload => {
  const result = createEndorsementStep1Schema.safeParse(state);
  return result.success;
};

export const isEndorsementStep2Valid = (state: FormState): state is UpdateEndorsementDataPayload => {
  const result = createEndorsementStep2Schema.safeParse(state);
  return result.success;
};

export const isEndorsementFormValid = (state: FormState): state is UpdateEndorsementDataPayload => {
  const step1IsValid = isEndorsementStep1Valid(state);
  const step2IsValid = isEndorsementStep2Valid(state);
  return step1IsValid && step2IsValid;
};
