import type { MouseEventHandler } from 'react';
import React from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import { teamsAccessor } from '@pages/User/helpers/columns/shared/teamsAccessor';
import type { TransformedCognitoUser } from '@root/@types/cognito';
import type { UserRole } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import language from '@src/language/language';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<TransformedCognitoUser>();

const USER_STATUSES = [
  {
    value: 'Active',
    label: 'Active',
    predicate: (user: any) => user.enabled && ['CONFIRMED', 'EXTERNAL_PROVIDER'].includes(user.status),
  },
  {
    value: 'Pending',
    label: 'Pending',
    predicate: (user: any) => !user.status || user.status === 'FORCE_CHANGE_PASSWORD',
  },
  {
    value: 'Disabled',
    label: 'Disabled',
    predicate: (user: any) => user.enabled === false,
  },
];

const getStatusLabel = (user: any) =>
  USER_STATUSES?.find(({ predicate }) => predicate(user))?.label ?? user?.status ?? '';

const Status = ({ user }: { user: any }) => {
  if (!user.status || user.status === 'FORCE_CHANGE_PASSWORD')
    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Pending
      </span>
    );
  if (user.status === 'RESET_REQUIRED')
    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Reset required
      </span>
    );
  if (user.enabled)
    return (
      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
        Active
      </span>
    );
  else
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Disabled
      </span>
    );
};

export const getUsersTableColumns = ({
  handleEditUserClick,
  handleResendButtonClick,
}: {
  handleEditUserClick: (user: TransformedCognitoUser) => MouseEventHandler<HTMLAnchorElement> | undefined;
  handleResendButtonClick: (user: TransformedCognitoUser) => MouseEventHandler<HTMLAnchorElement> | undefined;
}) => [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('first_name', {
    id: 'first_name',
    header: 'First Name',
    enableColumnFilter: false,
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
    },
  }),
  columnHelper.accessor('last_name', {
    id: 'last_name',
    header: 'Last Name',
    enableColumnFilter: false,
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
    },
  }),
  columnHelper.accessor((user) => (user.role ? language.user.role[user.role as UserRole] : user.role), {
    id: 'role',
    header: 'Role',
    filterFn: (row, _, filteredRoleLabels) =>
      filteredRoleLabels?.includes(language.user.role[row.original.role as UserRole]),
    meta: {
      filter: {
        placeholder: 'All roles',
      },
      tooltip: true,
    },
  }),
  teamsAccessor<TransformedCognitoUser>(columnHelper),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All emails',
      },
      tooltip: true,
    },
  }),
  columnHelper.accessor((user) => getStatusLabel(user), {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<TransformedCognitoUser, string>) => <Status user={cellContext.row.original} />,
    meta: {
      filter: {
        placeholder: 'All statuses',
      },
      dataCellClassName: '!w-24 whitespace-nowrap py-4 pl-0 align-top',
      headingCellClassName: '!w-24',
      headingLinkClassName: '!pl-0',
    },
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: 'Created at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<TransformedCognitoUser, string>) =>
      formatDate(cellContext.row.original.created_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
      headingLinkClassName: '!pl-0',
      dataCellClassName: '!pl-0 w-40',
    },
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<TransformedCognitoUser, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
      dataCellClassName: 'w-40',
    },
  }),
  columnHelper.display({
    id: 'edit',
    enableColumnFilter: false,
    cell: (cellContext) => (
      <ShowFirstMatching>
        <Show when={cellContext.row.original.status === 'FORCE_CHANGE_PASSWORD'}>
          <a
            href="#"
            onClick={handleResendButtonClick(cellContext.row.original)}
            className="text-primary-600 hover:text-primary-900"
          >
            Resend
          </a>
        </Show>
        <Show when={cellContext.row.original.status !== 'FORCE_CHANGE_PASSWORD'}>
          <a
            href="#"
            onClick={handleEditUserClick(cellContext.row.original)}
            className={'text-primary-600 hover:text-primary-900'}
          >
            Edit
          </a>
        </Show>
      </ShowFirstMatching>
    ),
    meta: {
      dataCellClassName: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative py-3.5 pl-3 pr-4 sm:pr-6 w-24',
    },
  }),
];
