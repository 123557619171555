import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authProvider } from '@helpers/auth';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { useUserStore } from './store';

export const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { clearAuthErrorCode, isLoggedIn, setUser } = useUserStore(({ clearAuthErrorCode, isLoggedIn, setUser }) => ({
    clearAuthErrorCode,
    isLoggedIn,
    setUser,
  }));

  const removeGlobalLoader = useRemoveGlobalLoader();

  useEffectOnce(() => {
    (async () => {
      try {
        clearAuthErrorCode();

        const currentUserInfo = await authProvider.getUserInfo();
        const hasUserInfo = !isEmpty(currentUserInfo);

        setIsLoading(false);
        setUser(hasUserInfo ? currentUserInfo : null);

        if (hasUserInfo) {
          Sentry.setUser({
            id: currentUserInfo.username,
            email: currentUserInfo.email,
          });
        }
      } catch {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    })();
  });

  useEffect(() => {
    if (!isLoading) {
      removeGlobalLoader();
    }
  }, [isLoading, removeGlobalLoader]);

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: new URLSearchParams(location.search).toString(),
        }}
        state={{ from: location }}
      />
    );
  }

  return <Outlet />;
};
