import { authProvider } from '@helpers/auth';
import type { BaseUser } from '@root/@types/types';
import * as Sentry from '@sentry/browser';
import { create } from 'zustand';

interface UserStoreBase {
  setUser: (user: BaseUser | null) => void;
  refreshUser: (updatedAt: string) => void;
  setAuthErrorCode: (errorCode: number) => void;
  clearAuthErrorCode: () => void;
  clearUser: () => void;
  updatedAt: string;
  authErrorCode: number | null;
}

interface UseUserStoreAuthenticated extends UserStoreBase {
  user: BaseUser;
  isLoggedIn: true;
}

interface UseUserStoreNotAuthenticated extends UserStoreBase {
  user: null;
  isLoggedIn: false;
}

export const useUserStore = create<UseUserStoreAuthenticated | UseUserStoreNotAuthenticated>((set) => ({
  isLoggedIn: false,
  updatedAt: '',
  user: null,
  authErrorCode: null,
  setUser: (user) => {
    if (!user) {
      return set({ user, isLoggedIn: false });
    }

    return set({ user, isLoggedIn: true });
  },
  clearUser: () => {
    Sentry.setUser(null);
    return set({ user: null, isLoggedIn: false, updatedAt: '' });
  },
  clearAuthErrorCode: () => {
    return set({ authErrorCode: null });
  },
  setAuthErrorCode: (errorCode) => {
    return set({ authErrorCode: errorCode });
  },
  refreshUser: async (updatedAt: string) => {
    const user = await authProvider.refreshUser();
    if (!user) {
      Sentry.setUser(null);
      return set({ user: null, isLoggedIn: false, updatedAt: '' });
    }
    return set({ user, isLoggedIn: true, updatedAt });
  },
}));
