import { DocumentMode, UserRole } from '@root/@types/types';

import { mrc } from './source/naming-mrc';

const language = {
  user: {
    role: {
      [UserRole.Admin]: 'Admin',
      [UserRole.Superadmin]: 'Super Admin',
      [UserRole.User]: 'User',
      [UserRole.TemplateManager]: 'Template Manager',
    },
  },
  menu: {
    pages: {
      submissions: 'Submissions',
      templates: 'Templates',
      blockLibrary: 'Block Library',
      tags: 'Tags',
      users: 'Users',
    },
    userBadge: {
      logout: 'Logout',
    },
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    saveChanges: 'Save changes',
    create: 'Create',
  },
  naming: {
    mrc,
  },
  'revision-history': {
    currentRevision: 'Current revision',
  },
  [DocumentMode.SUBMISSIONS]: {
    title: 'Contract',
    notifications: {
      statusUpdate: {
        archived: 'This contract is not being used.',
        final: 'This contract is complete.',
        draft: 'This contract is a work in progress.',
      },
    },
  },
  [DocumentMode.BRANDING]: {
    title: 'Branding',
    notifications: {
      statusUpdate: {
        archived: 'This branding is not available to use.',
        published: 'This branding is available to use.',
        draft: 'This branding is a work in progress.',
      },
    },
  },
  [DocumentMode.TEMPLATES]: {
    title: 'Templates',
    notifications: {
      statusUpdate: {
        archived: 'This template is not available to use.',
        published: 'This template is available to use.',
        draft: 'This template is a work in progress.',
      },
    },
  },
  [DocumentMode.ENDORSEMENTS]: {
    title: 'Endorsements',
    notifications: {
      statusUpdate: {
        archived: 'This endorsement is not being used.',
        final: 'This endorsement is complete.',
        draft: 'This endorsement is a work in progress.',
      },
    },
  },
};

export default language;
