import { toast } from 'react-toastify';
import { useUserStore } from '@Auth/store';
import { AuthErrorCode } from '@root/@types/types';
import type { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash-es';

const OMIT_ERROR_TOASTS = [
  {
    testUrl: (url: string) => url.split('/').includes('templates-spa-al'),
    method: 'get',
    status: 404,
  },
  {
    testUrl: (url: string) => url.split('/').includes('submissions-spa-al'),
    method: 'get',
    status: 404,
  },
];

const shouldSkipToast = (error: any): boolean => {
  return OMIT_ERROR_TOASTS.some((i) => {
    const urlMatches = i.testUrl(error.config.url);
    return urlMatches && i.method === error.config.method && i.status === error.response.status;
  });
};

const formatErrorMessage = (response?: AxiosResponse) =>
  get(response, 'data.message') || get(response, 'data.error.message') || 'Server Error';

/**
 * Check if the user is opening a new tab by checking if there is an aliasId in the url and no user in session storage
 */
const isNewTab = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const aliasId = urlParams.get('User');
  const userInStorage = sessionStorage.getItem('user');

  return aliasId && !userInStorage;
};

const isNewTabWithWrongAlias = (artificialErrorCode?: number) => {
  return artificialErrorCode === AuthErrorCode.AliasIdMismatch && isNewTab();
};

const isNewTabWithNoSession = (artificialErrorCode?: number) =>
  isNewTab() && (!artificialErrorCode || artificialErrorCode === AuthErrorCode.SessionNotFound);

const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

export const AxiosErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  const responseStatusCode = error.response?.status ?? 200;

  if ([401, 403].includes(responseStatusCode)) {
    useUserStore.getState().clearUser();
    const artificialErrorCode = parseInt(get(error, 'response.data.artificialErrorCode') ?? '') || 0;

    /*
    | In these scenarios, we want to skip showing the auth error page
    */
    if (!isEntra) return Promise.reject(error);
    if (isNewTabWithWrongAlias(artificialErrorCode)) return Promise.reject(error);
    if (isNewTabWithNoSession(artificialErrorCode)) return Promise.reject(error);

    useUserStore.getState().setAuthErrorCode(artificialErrorCode || AuthErrorCode.FailedAuthWithoutServerCode);

    return Promise.reject(error);
  }

  if (responseStatusCode >= 400 && responseStatusCode <= 599) {
    if (!shouldSkipToast(error)) {
      const formattedMessage = formatErrorMessage(error.response);
      toast.error(formattedMessage, {
        // 3 min
        autoClose: 3 * 60 * 1000,
        pauseOnHover: true,
      });
    }
  }

  return Promise.reject(error);
};

export const AxiosResponseInterceptor = (response: AxiosResponse): AxiosResponse => response;
