import type { FC } from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import type { ResourceSection } from '@root/@types/types';
import { isSummarySection } from '@root/helpers';
import { canChange } from '@root/src/helpers/canChange';
import { useCurrentContractSections, useDocumentLocation } from '@src/hooks';

import { Navigation } from '../views/Navigation';

interface NavigationProps {
  activeSectionId?: string;
}

export const NavigationController: FC<NavigationProps> = ({ activeSectionId }) => {
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const isViewingRevisionHistory = useIsRevisionHistory();
  const { isEndorsementView } = useDocumentLocation();
  const sections = useCurrentContractSections();

  if (!submission) {
    throw new Error(`Contract not found`);
  }

  const isGloballyDisabledByStatus = !canChange(submission?.status) || isViewingRevisionHistory;
  const isDragDisabled = isGloballyDisabledByStatus || isEndorsementView;

  const getShouldDisableOperations = (section: ResourceSection) => {
    if (isGloballyDisabledByStatus) {
      return false;
    }

    return isEndorsementView && !isSummarySection(section);
  };

  return (
    <Navigation
      activeSectionId={activeSectionId}
      isDragDisabled={isDragDisabled}
      sections={sections}
      getShouldDisableOperations={getShouldDisableOperations}
    />
  );
};
