import type { FC } from 'react';
import { BlockBody } from '@ContractBuilder/modules/block';
import { getBlockDeletedCopy } from '@ContractBuilder/modules/block-content';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import type { ResourceBlock } from '@root/@types/base';
import clsx from 'clsx';

import { blockContentClasses } from '../constants/classes';

interface BlockContentDeletedProps {
  block: ResourceBlock;
  isHighlighted?: boolean;
  isPdfRender?: boolean;
}

export const BlockContentDeleted: FC<BlockContentDeletedProps> = ({ block, isHighlighted, isPdfRender }) => {
  const { id, isVisible, name, order, title, type } = block;
  const content = getBlockDeletedCopy(title || name);

  return (
    <BlockBody
      id={id}
      name={name}
      order={order}
      // flags
      isPDFRender={isPdfRender}
      isEditing={false}
      isHighlighted={isHighlighted}
      isVisible={isVisible}
      isLockedFromEditing
    >
      <div className={clsx(blockContentClasses({ type }))}>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
      </div>
    </BlockBody>
  );
};
