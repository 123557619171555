import { Auth } from '@aws-amplify/auth';
import type { BaseUser } from '@root/@types/types';
import { cognitoUserAttributes } from '@root/helpers/auth';
import type { AxiosRequestConfig } from 'axios';
import { get } from 'lodash-es';

import type { IAuthProvider } from './types';

export class CognitoAuthProvider implements IAuthProvider {
  constructor() {
    Auth.configure({
      region: 'eu-west-1',
      userPoolId: import.meta.env?.VITE_COGNITO_USER_POOL_ID,
      userPoolWebClientId: import.meta.env?.VITE_COGNITO_WEB_CLIENT_ID,
      oauth: {
        domain: import.meta.env?.VITE_AUTH_DOMAIN,
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: import.meta.env?.VITE_COGNITO_REDIRECT,
        redirectSignOut: import.meta.env?.VITE_COGNITO_REDIRECT_SIGN_OUT,
        responseType: 'code',
      },
    });
  }

  async getIsLoggedIn() {
    try {
      return (await Auth.currentSession()).isValid();
    } catch {
      return false;
    }
  }

  async getUserInfo(): Promise<BaseUser | null> {
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      const attributes = cognitoUserAttributes(amplifyUser);

      return {
        email: attributes.email,
        firstName: attributes.given_name,
        lastName: attributes.family_name,
        role: attributes['custom:role'],
        sub: attributes.sub,
        tenantId: attributes['custom:tenantId'],
        tenantIds: JSON.parse(get(attributes, 'custom:tenant_ids', '[]')),
        username: amplifyUser.username,
      } as BaseUser;
    } catch {
      return null;
    }
  }

  async getNetworkAuthPayload(): Promise<AxiosRequestConfig | null> {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getIdToken().getJwtToken();

      return {
        headers: {
          Authorization: accessToken,
        },
      };
    } catch {
      return null;
    }
  }

  async logout() {
    await Auth.signOut();
  }

  async refreshUser() {
    const amplifyUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const currentSession = await Auth.currentSession();

    amplifyUser.refreshSession(currentSession.getRefreshToken(), async (err: any, session: any) => {
      if (err) {
        await Auth.signOut();
        return null;
      }
      amplifyUser.setSignInUserSession(session);
    });

    return await this.getUserInfo();
  }

  getAuthSearchParams() {}
}
