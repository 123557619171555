import { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { Show, ShowFirstMatching } from '@components/Show';
import { useEntityStore } from '@ContractBuilder/store';
import type { EndorsementData } from '@ContractBuilder/types';
import { buttonsContainerClasses, formContainerClasses } from '@features/create-entity-modal/classes';
import { Stepper } from '@features/create-entity-modal/components';
import type { StepperItemState } from '@features/create-entity-modal/types';
import { canChange } from '@helpers/canChange';
import useStep from '@hooks/use-step';
import { useEffectOnce } from '@hooks/useEffectOnce';
import { createEndorsement } from '@mutations/createEndorsement';
import { EndorsementPremiumChange } from '@root/@types/types';
import Nav from '@src/routes';
import clsx from 'clsx';
import { get } from 'lodash-es';

import { EndorsementSteps } from './components/EndorsementForm';
import { FormLoading } from './components/FormLoading';
import type { FormState } from './types';
import { isEndorsementFormValid, isEndorsementStep1Valid, isEndorsementStep2Valid } from './validation';

type CreateEndorsementModalProps = {
  handleClose: () => void;
  isEdit?: boolean;
};

export const EndorsementModal = ({ handleClose, isEdit = false }: CreateEndorsementModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { currentStep, isLast, isFirst, nextStep, prevStep, closeStepper } = useStep(2, handleClose);

  const { endorsement, updateEndorsement } = useEntityStore(({ submission, updateResourceData }) => ({
    endorsement: submission as EndorsementData,
    updateEndorsement: updateResourceData,
  }));

  const CurrentStep = EndorsementSteps[currentStep as 1 | 2];

  const submissionId = useParams().id;
  const create = createEndorsement(submissionId!);

  const openEndorsement = (id: string) => {
    if (id && submissionId) {
      const route = generatePath(Nav.EndorsementView, {
        id: submissionId,
        endorsement_id: id,
      });

      const win = window.open(route, '_self');
      win?.focus();
    }
  };

  const [state, setState] = useState<FormState>({
    premium_change: EndorsementPremiumChange.NONE,
  });

  const setFormValue = (name: keyof FormState, value?: string | object) => {
    setState((prev) => {
      const additionalPremium =
        name === 'premium_change' && value !== prev.premium_change ? null : prev.additional_premium;

      return { ...prev, additional_premium: additionalPremium, [name]: value };
    });
  };

  useEffectOnce(() => {
    const period = get(endorsement, 'data_items.risk_details.period[0]');

    if (isEdit && endorsement) {
      setState({
        additional_premium: endorsement.additional_premium,
        effective_date: endorsement.effective_date,
        expiry_date: endorsement.expiry_date,
        premium_change: endorsement.premium_change || EndorsementPremiumChange.NONE,
        reason: endorsement.reason,
        reference: endorsement.reference,
        type: endorsement.type,
      });
    } else if (period?.period_matrix) {
      const parsedExpiryDate = get(period, 'period_matrix.to.date');
      setFormValue('expiry_date', parsedExpiryDate);
    }

    return setIsInitialized(true);
  });

  const handleCreate = async () => {
    if (!isEndorsementFormValid(state)) {
      return;
    }

    setIsLoading(true);
    try {
      const { id } = await create(state);
      openEndorsement(id);
      closeStepper();
    } catch (_error) {
      // Swallow the error for now
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    if (!isEndorsementFormValid(state)) {
      return;
    }

    setIsLoading(true);
    try {
      await updateEndorsement(state);
      return closeStepper();
    } catch (_error) {
      // Swallow the error for now
    } finally {
      setIsLoading(false);
    }
  };

  const isStepValid = (step: 1 | 2) =>
    ({
      1: isEndorsementStep1Valid,
      2: isEndorsementStep2Valid,
    })[step](state);

  const getStepperItemState = (step: 1 | 2): StepperItemState => {
    const isActive = currentStep === step;
    return {
      isActive,
      isCompleted: isActive ? false : isStepValid(step),
    };
  };

  const canEditEndorsement = !isEdit || canChange(endorsement.status);
  const isDisabled = !isEndorsementFormValid(state) || !canEditEndorsement;
  const modalTitle = isEdit ? 'Edit an endorsement' : 'Create an endorsement';
  const isButtonDisabled = isDisabled || isLoading;
  const isCurrentStepValid = isStepValid(currentStep as 1 | 2);

  return (
    <Modal
      className="w-[32rem]"
      onClose={handleClose}
      open
      shouldShowCloseIcon={false}
      title={modalTitle}
      titleClassName="text-lg leading-6 font-medium !justify-center"
    >
      <div className={clsx(formContainerClasses, 'h-full')}>
        <p className="text-center text-sm font-normal leading-5 text-gray-500">
          Please fill in the following information to setup an endorsement
        </p>
        <div className="py-4">
          <Stepper getStepperItemState={getStepperItemState as any} size={2} />
        </div>
        <div className="flex h-full w-full flex-col gap-6">
          <Show when={isInitialized}>
            {CurrentStep && <CurrentStep setFormValue={setFormValue} state={state} isReadOnly={!canEditEndorsement} />}
          </Show>
          <Show when={!isInitialized}>
            <FormLoading />
          </Show>
          <div className={clsx(buttonsContainerClasses)}>
            <Button className="h-[2.375rem]" kind="secondary" onClick={prevStep}>
              {isFirst ? 'Cancel' : 'Previous'}
            </Button>
            <ShowFirstMatching>
              <Show when={isLast}>
                <Button
                  className="h-[2.375rem]"
                  isDisabled={isButtonDisabled}
                  onClick={isEdit ? handleEdit : handleCreate}
                  type="button"
                  kind="primary"
                >
                  {isEdit ? 'Update' : 'Create'}
                </Button>
              </Show>
              <Show when={!isLast}>
                <Button
                  className="h-[2.375rem]"
                  onClick={nextStep}
                  type="button"
                  kind="primary"
                  isDisabled={!isCurrentStepValid}
                >
                  Next
                </Button>
              </Show>
            </ShowFirstMatching>
          </div>
        </div>
      </div>
    </Modal>
  );
};
