import type { FC } from 'react';
import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AdminRoutes } from '@Auth/AdminRoutes';
import Logout from '@Auth/Logout';
import { RedirectSubmissionsLegacy } from '@components/RedirectSubmissionsLegacy';
import { Spinner } from '@components/Spinner';
import { PrivateRoutes, PublicRoutes } from '@containers/Auth';
import { IconReplacement, toastBodyClassName, toastClassName } from '@ContractBuilder/components/Notifications';
import { ErrorPage, sentryErrorFallback } from '@pages/ErrorPage';
import * as Sentry from '@sentry/browser';
import {
  BrowserTracing,
  reactRouterV6Instrumentation,
  withErrorBoundary,
  withProfiler,
  withSentryReactRouterV6Routing,
} from '@sentry/react';
import { useFeatureFlags } from '@src/hooks';
import { initStructuredClonePolyfill } from '@utils/structured-clone-polyfill';
import axios from 'axios';

import ForgotPassword from './containers/Auth/ForgotPassword';
import Login from './containers/Auth/Login';
import PasswordForm from './containers/Auth/PasswordForm';
import ContractBuilderPage from './pages/ContractBuilder';
import MarkerIOPlugin from './plugins/marker-io';
import { AxiosErrorInterceptor, AxiosResponseInterceptor } from './utils/axios-err-interceptor';
import {
  UserBlocksPage,
  UserBrandingPage,
  UserMrcExtractionPage,
  UserSubmissionsPage,
  UserTagsPage,
  UserTemplatesPage,
  UserUsersPage,
} from './pages';
import Nav from './routes';

import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient();

axios.interceptors.response.use(AxiosResponseInterceptor, AxiosErrorInterceptor);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 0.25,
  release: import.meta.env.VITE_SENTRY_RELEASE,
});
// eslint-disable-next-line react-refresh/only-export-components
const SentryRoutes = withSentryReactRouterV6Routing(Routes);

initStructuredClonePolyfill();

// eslint-disable-next-line react-refresh/only-export-components
const App: FC = () => {
  const getFeatureFlagState = useFeatureFlags();
  const isBrandingEnabled = getFeatureFlagState('Branding');
  const isUsersEnabled = getFeatureFlagState('Users');

  return (
    <QueryClientProvider client={queryClient}>
      <MarkerIOPlugin />
      <Suspense fallback={<Spinner />}>
        <Router>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            icon={IconReplacement}
            toastClassName={toastClassName}
            bodyClassName={toastBodyClassName}
          />

          <SentryRoutes>
            <>
              {/* PUBLIC */}
              <Route element={<PublicRoutes />}>
                <Route path={Nav.Register} element={<PasswordForm />} />
                <Route path={Nav.Login} element={<Login />} />
                <Route path={Nav.Auth} element={<Login />} />
                <Route path={Nav.Logout} element={<Logout />} />
                <Route path={Nav.ForgotPassword} element={<ForgotPassword />} />
                <Route path={Nav.ResetPassword} element={<PasswordForm />} />
                <Route path="/404" element={<ErrorPage type={'404'} />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              {/* PRIVATE */}
              <Route element={<PrivateRoutes />}>
                {/* WITH MENU */}
                <Route path={Nav.SubmissionsLegacy} element={<RedirectSubmissionsLegacy />} />
                <Route path={Nav.Submissions} element={<UserSubmissionsPage />} />
                <Route path={Nav.MrcExtraction} element={<UserMrcExtractionPage />} />
                <Route element={<AdminRoutes />}>
                  <Route path={Nav.Templates} element={<UserTemplatesPage />} />
                  <Route path={Nav.Blocks} element={<UserBlocksPage />} />
                  <Route path={Nav.Tags} element={<UserTagsPage />} />
                  {isUsersEnabled && <Route path={Nav.Users} element={<UserUsersPage />} />}
                  {isBrandingEnabled && <Route path={Nav.Branding + '/*'} element={<UserBrandingPage />} />}
                </Route>
                {/* WITH MENU END */}
                {/* CONTRACT BUILDER */}
                <Route path={Nav.Submission} element={<ContractBuilderPage />} />
                <Route path={Nav.Template} element={<ContractBuilderPage />} />
                <Route path={Nav.Endorsement} element={<ContractBuilderPage />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              {/* Handlers */}
              <Route path="/" element={<Navigate to={Nav.Submissions} />} />
            </>
          </SentryRoutes>
        </Router>
      </Suspense>
    </QueryClientProvider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default withProfiler(withErrorBoundary(App, { fallback: sentryErrorFallback }), {
  name: 'contract-builder-frontend',
});
