import { useMemo, useState } from 'react';

// Steps start from 1
function useStep(totalSteps = 1, onClose?: () => void) {
  if (totalSteps <= 0) {
    throw new Error('Total steps must be greater than 0');
  }

  const [currentStep, setCurrentStep] = useState(1);

  const isLast = useMemo(() => currentStep === totalSteps, [currentStep, totalSteps]);
  const isFirst = useMemo(() => currentStep === 1, [currentStep]);

  const nextStep = () => {
    setCurrentStep((prev) => (prev < totalSteps ? prev + 1 : prev));
  };

  const prevStep = () => {
    setCurrentStep((prev) => {
      const isFirstStep = prev <= 1;

      if (isFirstStep) {
        onClose?.();
        return 1;
      }

      return prev - 1;
    });
  };

  const resetStep = () => {
    setCurrentStep(1);
  };

  const closeStepper = () => {
    setCurrentStep(1);
    onClose?.();
  };

  return {
    currentStep,
    isLast,
    isFirst,
    nextStep,
    prevStep,
    resetStep,
    closeStepper,
  };
}

export default useStep;
