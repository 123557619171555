const InputItem = () => (
  <div className="flex animate-pulse flex-col gap-2">
    <div className="h-5 w-24 rounded-lg bg-slate-200" />
    <div className="h-10 w-full rounded-md border bg-slate-200" />
  </div>
);

export const FormLoading = () => {
  return (
    <>
      <InputItem />
      <InputItem />
      <InputItem />
      <InputItem />
    </>
  );
};
