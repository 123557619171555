import type { FC } from 'react';
import { isSubmissionPath } from '@utils/app-paths';

import type { EntityModalType } from '../types';
import { CreateEntityModal } from '../views/CreateEntityModal';

interface CreateEntityModalControllerProps {
  handleClose: () => void;
}

export const CreateEntityModalController: FC<CreateEntityModalControllerProps> = ({ handleClose }) => {
  const isSubmission = isSubmissionPath();
  const type: EntityModalType = isSubmission ? 'contract' : 'template';

  return <CreateEntityModal onClose={handleClose} type={type} />;
};
