import React from 'react';
import type { EntityData } from '@ContractBuilder/types';
import { tagsAccessor, teamsAccessor } from '@pages/User/helpers/columns/shared';
import {
  brandingAccessor,
  endorsementsAccessor,
  renewalsAccessor,
  statusAccessor,
} from '@pages/User/helpers/columns/submissions';
import type { Tag, UISelectionType } from '@root/@types/types';
import { formatDate, isNonNullish } from '@root/helpers';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';

type Submission = EntityData & { created_by: string };

const columnHelper = createColumnHelper<Submission>();

interface GetSubmissionTableColumnsOptions {
  brandingFieldName: 'branding' | 'branding_id';
  brandingOptions: UISelectionType[] | string[];
  disableStatus?: boolean;
  hasEndorsementsFeature: boolean;
  tags: Tag[];
}

export const getSubmissionsTableColumns = ({
  brandingFieldName,
  brandingOptions,
  disableStatus = false,
  hasEndorsementsFeature,
  tags,
}: GetSubmissionTableColumnsOptions) =>
  [
    columnHelper.accessor('id', {
      id: 'id',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('ref', {
      id: 'ref',
      enableColumnFilter: false,
      header: 'Contract ID',
      filterFn: 'arrIncludesSome',
      meta: {
        filter: {
          placeholder: 'All IDs',
        },
        dataCellClassName: '!w-36',
        headingCellClassName: '!w-36',
      },
      sortingFn: (rowA, rowB) =>
        Number((rowA.original.ref ?? '').replace('ART-S-', '')) -
        Number((rowB.original.ref ?? '').replace('ART-S-', '')),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      enableColumnFilter: false,
      header: 'Name',
      sortingFn: 'text',
      sortUndefined: -1,
      filterFn: 'arrIncludesSome',

      meta: {
        tooltip: true,
        filter: {
          label: 'Contract name',
          placeholder: 'All Contracts',
        },
      },
    }),
    columnHelper.accessor('umr', {
      id: 'umr',
      enableColumnFilter: false,
      header: 'UMR',
      sortingFn: 'text',
      sortUndefined: -1,
      filterFn: 'arrIncludesSome',
      meta: {
        tooltip: true,
        filter: {
          placeholder: 'All UMRs',
        },
        headingCellClassName: 'w-44',
      },
    }),
    teamsAccessor<Submission>(columnHelper, 'Team'),
    hasEndorsementsFeature ? endorsementsAccessor<Submission>(columnHelper) : null,
    hasEndorsementsFeature ? renewalsAccessor<Submission>(columnHelper) : null,
    disableStatus ? null : statusAccessor<Submission>(columnHelper),
    tagsAccessor<Submission>(columnHelper, tags),
    columnHelper.accessor('class_of_business', {
      id: 'class_of_business',
      header: 'Class of Business',
      filterFn: 'arrIncludesSome',
      meta: {
        filter: {
          placeholder: 'All classes',
          options: ALL_CLASSES_OF_BUSINESS,
        },
      },
    }),
    columnHelper.accessor('created_by', {
      id: 'created_by',
      header: 'Created by',
      filterFn: 'arrIncludesSome',
      meta: {
        filter: {
          placeholder: 'All created by',
        },
      },
    }),
    columnHelper.accessor('sourceResourceDisplayName', {
      id: 'sourceResourceDisplayName',
      header: 'Template',
      filterFn: 'arrIncludesSome',
      meta: {
        filter: {
          placeholder: 'All templates',
        },
      },
    }),
    brandingAccessor<Submission>(columnHelper, brandingFieldName, brandingOptions),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      enableColumnFilter: false,
      sortingFn: 'datetime',
      sortUndefined: -1,
    }),
    columnHelper.accessor('updated_at', {
      id: 'updated_at',
      header: 'Updated at',
      enableColumnFilter: false,
      cell: (cellContext: CellContext<Submission, string>) =>
        formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
      sortingFn: 'datetime',
      sortUndefined: -1,
      meta: {
        headingCellClassName: 'w-40',
      },
    }),
    columnHelper.display({
      id: 'view',
      enableColumnFilter: false,
      cell: (cellContext: CellContext<Submission, unknown>) => (
        <a
          className="font-light text-primary-600 hover:text-primary-900"
          target="_self"
          href={`/contracts/${cellContext.row.getValue('id')}/contract`}
          rel="noreferrer"
        >
          View
        </a>
      ),
      meta: {
        dataCellClassName:
          'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
        headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
      },
    }),
  ].filter(isNonNullish);
